<!-- 自定义隐私协议弹窗，从问卷sdk中复制过来，稍有改动 -->
<template>
  <div class="privacy-compliance-custom">
    <vh-dialog
      title="隐私协议设置"
      :visible.sync="dialogVisbale"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      width="600px"
      :before-close="handleClose"
    >
      <div class="privacy_box">
        <div class="flex_box">
          <span class="labelRight">隐私协议</span>
          <ComInput
            :max-length="100"
            v-model="statement_content"
            @change="val => (item.title = val)"
          ></ComInput>
        </div>
        <div v-for="(item, index) in statement_info" :key="index" class="posi">
          <div class="flex_box">
            <span class="labelRight">协议名称</span>
            <ComInput
              :max-length="100"
              v-model="item.title"
              @change="val => (item.title = val)"
              @blur="
                el => {
                  changeName(el, item.title, index);
                }
              "
            ></ComInput>
            <span class="wid_i">
              <i
                class="iconfont-v3 saasicon_fangda"
                @click="addOption"
                v-show="statement_info.length == 1"
              ></i>
              <i
                class="vh-icon-remove-outline"
                @click="delOption(item.title, index)"
                v-show="index == 1"
              ></i>
            </span>
          </div>
          <div v-show="item.titleErrTip" class="titleErrTip">
            {{ item.titleErrTip }}
          </div>
          <div class="flex_box">
            <span class="labelRight">跳转链接</span>
            <ComInput
              class="tarea"
              @blur="el => checkUrl(item)"
              v-model="item.link"
              :max-length="200"
              type="textarea"
              placeholder="请输入以http://或https://开头的链接"
              @change="val => (item.link = val)"
            ></ComInput>
            <span class="block_"></span>
          </div>
          <div v-if="item.urlErrTip" class="urlErrTip">
            {{ item.urlErrTip }}
          </div>
        </div>
        <div class="flex_box">
          <span class="labelRight">预览效果</span>
          <div v-html="previewHtm" class="previewH"></div>
        </div>
      </div>
      <span slot="footer">
        <vh-button size="medium" plain type="info" @click="handleClose" round>取消</vh-button>
        <vh-button type="primary" size="medium" round @click="savePrivacy">确定</vh-button>
      </span>
    </vh-dialog>
  </div>
</template>

<script>
  import ComInput from './ComInput.vue';
  export default {
    name: 'PrivacyComplianceCustom',
    components: {
      ComInput
    },
    props: {
      defaContent: {
        type: String,
        default: ''
      },
      defaInfo: {
        type: Array,
        default() {
          return;
        }
      }
    },
    data() {
      return {
        statement_content: '我们根据《隐私声明》保护您填写的所有信息',
        statement_info: [{ title: '《隐私声明》', link: '', urlErrTip: '' }],
        previewHtm: '',
        errTipShow: false,
        dialogVisbale: false
      };
    },
    watch: {
      defaContent: {
        handler(val) {
          // console.log('defaContent---:', val);
          this.statement_content = val;
        },
        immediate: true
      },
      defaInfo: {
        handler(val) {
          // console.log('defaInfo---:', val);
          if (!val) return;
          this.statement_info = JSON.parse(JSON.stringify(val));
          this.statement_info.forEach(i => {
            this.$set(i, 'titleErrTip', '');
            this.$set(i, 'urlErrTip', '');
          });
          this.assemble();
        },
        immediate: true
      },
      statement_content: {
        handler(val) {
          this.assemble();
        },
        immediate: true
      }
    },
    methods: {
      // 关闭弹框
      handleClose() {
        this.dialogVisbale = false;
      },
      // 保存协议内容
      savePrivacy() {
        let hasError = false;
        for (const item of this.statement_info) {
          this.checkTitle(item);
          this.checkUrl(item);
          if (item.titleErrTip || item.urlErrTip) hasError = true;
        }
        if (hasError) return;
        if (
          this.statement_info.length > 1 &&
          this.statement_info[0].title === this.statement_info[1].title
        ) {
          this.$vhMessage.error('隐私协议名称不能重复');
          return;
        }
        this.handleClose();
        this.$emit('savePrivacy', {
          statement_info: this.statement_info,
          statement_content: this.statement_content
        });
      },
      // 添加协议
      addOption() {
        if (this.statement_content.length > 92) {
          this.$message.warning('添加隐私协议会超出预览字数，请删减后再添加');
          return;
        }
        this.statement_info.push({
          title: `《隐私声明2》`,
          link: '',
          urlErrTip: '',
          titleErrTip: ''
        });
        this.statement_content = this.statement_content.replace(
          this.statement_info[0].title,
          `${this.statement_info[0].title}和${this.statement_info[1].title}`
        );
        console.log(this.statement_content, this.statement_info, 'addOption');
      },
      // 删除协议
      delOption(val, index) {
        let str = index == 0 ? `${val}和` : `和${val}`;
        this.statement_content = this.statement_content.replace(str, '');
        this.statement_info = this.statement_info.filter((item, i) => i != index);
        console.log(
          this.statement_content,
          this.statement_content.replace(str, ''),
          str,
          index,
          'delOption'
        );
        this.assemble();
      },
      // 修改协议名称
      changeName(el, val, index) {
        console.log(val, 'changeName');
        if (this.statement_content.includes(val)) {
          this.statement_info[index].title = val;
          this.statement_info[index].titleErrTip = '';
        } else {
          this.statement_info[index].title = '';
        }
        this.assemble();
      },
      // 预览内容拼装
      assemble() {
        this.previewHtm = this.$parent.createCustomPreviewHtm(
          this.statement_content,
          this.statement_info
        );
      },
      checkTitle(item) {
        if (!item.title) item.titleErrTip = '请输入协议名称';
        else if (item.title && this.statement_content.indexOf(item.title) == -1) {
          item.titleErrTip = '协议名称必须被包含在第1行的文字中';
        } else {
          item.titleErrTip = '';
        }
      },
      // 检测link
      checkUrl(item) {
        let flag = /^(((https|http)?):\/\/)[0-9,a-z,A-Z]+/.test(item.link);
        item.urlErrTip = flag ? '' : '请输入正确的网址';
        if (flag) {
          this.assemble();
        }
      }
    }
  };
</script>
<style lang="less">
  .privacy-compliance-custom {
    box-sizing: border-box;
    * {
      box-sizing: border-box;
    }
    .com-input {
      margin-right: 8px;
    }
    .com-input.chose-item {
      input:hover {
        border-color: #8c8c8c;
      }
    }

    .posi {
      position: relative;
      .urlErrTip {
        position: absolute;
        bottom: -18px;
        left: 74px;
        color: #fb3a32;
        font-size: 12px;
        line-height: 1;
      }
      .titleErrTip {
        position: absolute;
        bottom: 60px;
        left: 74px;
        color: #fb3a32;
        font-size: 12px;
        line-height: 1;
      }
    }
    .flex_box {
      display: flex;
      margin-bottom: 20px;
      .labelRight {
        flex-shrink: 0;
        width: 75px;
        line-height: 40px;
      }
      .tarea.com-input {
        height: 56px;
      }
      .limit.area {
        transform: none;
        bottom: 4px;
        right: 8px;
      }
      .wid_i {
        width: 16px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        i {
          font-size: 20px;
        }
      }
      .saasicon_fangda,
      .saasicon_delete {
        line-height: 40px;
        cursor: pointer;
      }
      .block_ {
        width: 16px;
        flex-shrink: 0;
      }
      .previewH {
        margin-top: 6px;
        line-height: 2;
        text-align: left;
        a {
          text-decoration: none;
          color: #3562fa;
        }
      }
      .sure {
        line-height: 40px;
        margin-right: 8px;
      }
    }
    .preview_pad {
      padding: 0 75px;
      font-size: 14px;
      position: relative;
      color: #606266;
      .errtip {
        position: absolute;
        flex-shrink: 0;
        color: #fb3a32;
        font-size: 12px;
        bottom: -5px;
      }
    }
    .preview_pad_wap {
      padding: 20px;
      .previewH,
      .sure {
        line-height: 1;
      }
      .errtip {
        bottom: 0;
      }
    }
  }
</style>
