<template>
  <div class="privacy-compliance-choose">
    <vh-form :model="value">
      <!-- 系统自带协议选项 -->
      <vh-form-item class="compliance-choose-item">
        <vh-radio
          :label="0"
          :value="value.statement_status"
          @change="change"
          @input.native.prevent="updateValue('statement_status', $event.target.value)"
        >
          展示系统自带协议
        </vh-radio>
        <div class="privacy-compliance-preview">
          预览效果:
          <div class="privacy-compliance-preview__htm" v-html="defaultPreviewHtm"></div>
        </div>
      </vh-form-item>
      <!-- 自定义协议选项 -->
      <vh-form-item class="compliance-choose-item">
        <div class="choice-row">
          <!-- 是否使用自定义隐私协议 0:否（系统默认）， 1:是 -->
          <vh-radio
            :label="1"
            :value="value.statement_status"
            @change="change"
            @input.native.prevent="updateValue('statement_status', $event.target.value)"
          >
            自定义协议
          </vh-radio>
          <vh-button type="text" class="btn-custom" @click="openCustomDialog">去设置</vh-button>
        </div>
        <div class="privacy-compliance-preview" v-show="customPreviewHtm">
          预览效果:
          <div class="privacy-compliance-preview__htm" v-html="customPreviewHtm"></div>
        </div>
      </vh-form-item>
    </vh-form>

    <!-- 自定义协议设置对话框 -->
    <ComplianceCustom
      ref="complianceCustomDialog"
      :defaContent="statement_content"
      :defaInfo="statement_info"
      @savePrivacy="savePrivacy"
    ></ComplianceCustom>
  </div>
</template>
<script>
  import { v1 as uuidV1 } from 'uuid';
  import ComplianceCustom from './ComplianceCustom.vue';

  export default {
    name: 'PrivacyComplianceChoose',
    components: {
      ComplianceCustom
    },
    props: {
      // 接收v-model中的数据
      value: {
        type: Object,
        required: true,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        // 系统默认隐私协议预览htm
        defaultPreviewHtm: `提交即同意
            <a href="https://e.vhall.com/v3/privacyPolicy" target="_blank" rel="noopener noreferrer">《隐私政策》</a>
            及
            <a href="https://e.vhall.com/v3/privacyUPo" target="_blank" rel="noopener noreferrer">《用户保护协议》</a>`,

        // 传递给自定义协议设置对话框初始数据
        statement_content: '我们根据《隐私声明》保护您填写的所有信息', // 自定义隐私协议文本默认内容
        statement_info: [{ title: '《隐私声明》', link: '' }], // 默认自定义隐私协议关键词及链接 title,link
        customPreviewHtm: '', // 自定义隐私协议预览htm

        currentPreviewHtm: '' // 当前选中的隐私协议的预览文本
      };
    },
    watch: {
      'value.statement_content': {
        handler(val) {
          // console.log('===watch value.statement_content:', val);
          if (val) {
            this.statement_content = val;
            this.updateCustomPreviewHtm();
          }
        },
        immediate: true
      },
      'value.statement_info': {
        handler(val) {
          // console.log('===watch value.statement_info:', val);
          if (Array.isArray(val) && val.length > 0) {
            this.statement_info = JSON.parse(JSON.stringify(val));
            this.updateCustomPreviewHtm();
          }
        },
        immediate: true
      }
    },
    mounted() {
      this.updateCustomPreviewHtm();
    },
    methods: {
      // 当前
      change(value) {
        this.$emit('change', {
          statement_status: value,
          privacyHtm: this.getCurrentPreviewHtm()
        });
      },
      updateValue(key, newValue) {
        if (typeof key === 'object') {
          this.$emit('input', { ...this.value, ...key });
        } else {
          if (key === 'statement_status') newValue = Number(newValue);
          this.$emit('input', { ...this.value, [key]: newValue });
        }
      },
      // 生成自定义隐私协议预览
      createCustomPreviewHtm(content, arr = []) {
        let htm = content;
        for (const item of arr) {
          if (item.title) {
            if (!item.uuid) item.uuid = uuidV1(); // 先替换为占位符，保证唯一
            htm = htm.replace(item.title, item.uuid);
          }
        }
        for (const item of arr) {
          if (item.title) {
            htm = htm.replace(
              item.uuid,
              `<a href="${item.link || 'javascript:void(0);'}" target="${
                item.link ? '_blank' : '_self'
              }" rel="noopener noreferrer">${item.title}</a>`
            );
          }
        }
        return htm;
      },
      // 更新自定义隐私协议预览内容
      updateCustomPreviewHtm() {
        this.customPreviewHtm = this.createCustomPreviewHtm(
          this.value.statement_content,
          this.value.statement_info
        );
        // 协议变更
        this.change(this.value.statement_status);
      },
      // 打开自定义隐私协议弹框
      openCustomDialog() {
        this.$refs.complianceCustomDialog.dialogVisbale = true;
      },
      // 保存协议数据
      async savePrivacy(data) {
        // console.log('savePrivacy data:', data);
        this.updateValue({
          statement_content: data.statement_content,
          statement_info: data.statement_info
        });
        this.updateCustomPreviewHtm();
      },
      getCurrentPreviewHtm() {
        if (this.value.statement_status == 1) {
          return this.customPreviewHtm;
        } else {
          return this.defaultPreviewHtm;
        }
      }
    }
  };
</script>
<style lang="less">
  .privacy-compliance-choose {
    .compliance-choose-item {
      .choice-row .btn-custom {
        cursor: pointer;
        color: #3562fa;
      }
    }

    .privacy-compliance-preview {
      font-size: 14px;
      color: #666;
      background: rgba(226, 226, 226, 0.4);
      line-height: 40px;
      padding-left: 10px;
      border-radius: 4px;

      .privacy-compliance-preview__htm {
        display: inline-block;
        /* 设置元素自动换行 */
        word-wrap: break-word;
        white-space: normal;
        line-height: 1.5;
      }

      a {
        cursor: pointer;
        color: #3562fa;
      }
    }
  }
</style>
