<template>
  <div class="com-input" :class="{ area: type === 'textarea' }">
    <input
      v-if="type === 'text'"
      type="text"
      ref="input"
      :placeholder="placeholder"
      :disabled="disabled"
      @focus="focusHandle"
      @blur="blurHandle"
      v-model="tempValue"
    />
    <textarea
      v-if="type === 'textarea'"
      type="text"
      :placeholder="placeholder"
      :rows="rows"
      ref="input"
      @focus="focusHandle"
      @blur="blurHandle"
      v-model="tempValue"
    ></textarea>
    <div
      ref="limit"
      class="limit"
      :class="{ area: type === 'textarea' }"
      v-if="showLength && maxLength && getLength >= 0"
    >
      <span
        :class="
          getLength > 0 && getLength < maxLength
            ? 'length'
            : getLength == maxLength
            ? 'maxLength'
            : ''
        "
      >
        {{ getLength }}
      </span>
      /
      <span>{{ maxLength }}</span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ComInput',
    props: {
      placeholder: String,
      value: {
        type: String,
        default: ''
      },
      format: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: 'text'
      },
      maxLength: {
        type: Number,
        default: 0
      },
      showLength: {
        type: Boolean,
        default: true
      },
      rows: {
        type: Number,
        default: 4
      },
      disabled: {
        type: Boolean,
        default: false
      },
      isChar: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        tempValue: '',
        limitWidth: 0,
        oldValue: '',
        defaultHolder: ''
      };
    },
    methods: {
      focusHandle(e) {
        // console.log(e)
        // console.log(this.$refs.input.value)
        this.oldValue = this.$refs.input.value;
        this.defaultHolder = this.$refs.input.getAttribute('placeholder');
        if (this.defaultHolder === '请输入题目' && this.oldValue === '题目') {
          this.$refs.input.value = '';
        } else if (this.defaultHolder === '请输入选项' && this.oldValue === '选项') {
          this.$refs.input.value = '';
        } else if (this.defaultHolder === '标题' && this.oldValue === '标题') {
          this.$refs.input.value = '标题';
          // this.tempValue = ''
        } else if (this.defaultHolder === '请填写简介' && this.oldValue === '简介') {
          this.$refs.input.value = '';
        }
        setTimeout(() => {
          if (/Android/gi.test(navigator.userAgent)) {
            e.target.scrollIntoView({
              behavior: 'smooth'
            });
          }
        }, 10);

        this.$emit('focus', e);
      },
      blurHandle(e) {
        if (!this.$refs.input.value) {
          if (this.defaultHolder == '标题' || this.defaultHolder == '请填写简介') {
            return;
          }
          this.$refs.input.value = this.oldValue;
          this.$emit('change', this.oldValue);
        } else {
          this.oldValue = '';
        }
        this.$emit('blur', e);
      }
    },
    watch: {
      value: {
        handler(value) {
          this.tempValue = value.replace(/(^\s*)/g, '');
        },
        immediate: true
      },
      tempValue(value) {
        if (this.maxLength && this.getLength > this.maxLength) {
          this.tempValue = value.substring(0, this.getIndex);
        }
        this.$emit('input', this.tempValue);
      }
    },
    computed: {
      getLength() {
        if (this.$refs.limit) {
          this.limitWidth = this.$refs.limit.offsetWidth;
        }
        return this.isChar ? this.tempValue.length : this.tempValue.gbLength();
      },
      getIndex() {
        return this.isChar ? this.maxLength : this.tempValue.gbIndex(this.maxLength) + 1;
      },
      style() {
        return {
          paddingRight: `${this.limitWidth + 20}px`
        };
      }
    }
  };
</script>

<style lang="less" scoped>
  .com-input ::v-deep {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 40px;
    input {
      outline: none;
      display: inline-block;
      width: 100%;
      height: 100%;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      background-color: #fff;
      background-image: none;
      border: 1px solid #d2d2d2;
      border-radius: 4px;
      padding: 0 10px;
      color: #1a1a1a;
      padding-right: 60px;
      &:focus {
        border: 1px solid #999;
        color: #1a1a1a;
      }
      &::-moz-placeholder {
        color: #bfbfbf;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        color: #bfbfbf;
      }
      &::-webkit-input-placeholder {
        color: #bfbfbf;
      }
      &[disabled] {
        cursor: not-allowed;
      }
    }
    .limit {
      font-size: 14px;
      color: #999999;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 8px;
      .length {
        color: #262626;
      }
      .maxLength {
        color: #fb3a32;
      }
      &.area {
        transform: translateX(110%);
        top: auto;
        bottom: 0;
        line-height: normal;
        right: 0;
      }
    }
    textarea {
      resize: none;
      outline: none;
      display: inline-block;
      padding: 4px 10px;
      width: 100%;
      height: 100%;
      font-size: 14px;
      line-height: 1.5;
      color: rgba(0, 0, 0, 0.65);
      background-color: #fff;
      background-image: none;
      border: 1px solid #d2d2d2;
      border-radius: 4px;
      // color: #999;
      color: #1a1a1a;
      // padding-right: 60px;
      font-family: '-apple-system', 'BlinkMacSystemFon', 'Helvetica Neue', Helvetica, 'PingFang SC',
        'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
      &.error {
        border-color: #fc5659;
      }
      &:focus {
        border: 1px solid #999;
        color: #666;
      }
      &::-moz-placeholder {
        color: #bfbfbf;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        color: #bfbfbf;
      }
      &::-webkit-input-placeholder {
        color: #bfbfbf;
      }
      &[disabled] {
        cursor: not-allowed;
      }
    }
  }
</style>
